import { Component, Vue } from 'vue-property-decorator';

import AlertMessage from '@/core/spe/alert-message/alert-message.vue';
import { IEmployee } from '@/shared/model/employee.model';
import PersonalData from '@/account/spe/employee-details/tabs/personal-data/personal-data.vue';
import SecurityData from '@/account/spe/employee-details/tabs/security-data/security-data.vue';
import { IImage } from '@/shared/model/image.model';

const validations: any = {};
@Component({
  components: {
    'personal-data': PersonalData,
    'security-data': SecurityData,
    'alert-message': AlertMessage,
  },
  validations,
})
export default class EmployeeDetail extends Vue {
  public currentLanguage = '';
  public employee: IEmployee = {};
  public image = '';
  public isLoading = false;

  /**
   * Stacks tabs on top of each other vertically.
   */
  public get vertical(): boolean {
    return !this.$vuetify.breakpoint.xs;
  }

  created(): void {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
      }
    );

    this.employee = this.$store.getters.employee;
    this.$store.watch(
      () => this.$store.getters.employee,
      () => {
        this.employee = this.$store.getters.employee;
      }
    );
    this.$v.$reset();
  }

  public updateIsLoading(e: any): void {
    this.isLoading = e;
  }
}
