import { Component, Emit, Inject, Vue } from 'vue-property-decorator';
import { GoAppsComponent as GoApps, GoLanguageComponent as GoLanguage } from '@gv/ammo-grome';
import EmployeeAccount from '@/core/spe/employee-account/employee-account.vue';
import TranslationService from '@/locale/translation.service';
import LoginService from '@/account/login.service';

/*
 * @author ricardo.gaspar@grupo-valco.com
 */
@Component({
  components: {
    'employee-account': EmployeeAccount,
    'go-apps': GoApps,
    'go-language': GoLanguage,
  },
})
export default class AppBar extends Vue {
  @Inject('loginService')
  private loginService!: () => LoginService;

  @Inject('translationService')
  private translationService!: () => TranslationService;

  public currentLanguage = '';

  // eslint-disable-next-line
  private languages: { [k: string]: any } = this.$store.getters.languages;

  created() {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
      }
    );
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  @Emit('drawerClick')
  // eslint-disable-next-line
  drawerBubbleUp() {
  }

  @Emit('drawerRightClick')
  // eslint-disable-next-line
  drawerRightBubbleUp() {
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
    // SPE Begin vuetify internationalization
    this.$vuetify.lang.current = newLanguage.substring(0, 2);
    // SPE End vuetify internationalization
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public openLogin() {
    const next = '/auth';
    return this.$router.push(next);
  }

  public logout() {
    this.loginService().logout();
    this.$router.push({ name: 'EmployeeAuthentication' });
  }
}
