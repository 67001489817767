/* eslint-disable */
export enum AlertMessagePropertiesType {
  Error = 'error',
  Success = 'success',
}

export interface IAlertMessageProperties {
  type?: AlertMessagePropertiesType | null;
  title?: string | null;
  message?: string | null;
  buttonText?: string | null;
  visible?: boolean;
}

export class AlertMessageProperties implements IAlertMessageProperties {
  constructor(
    public type: AlertMessagePropertiesType | null = null,
    public title: string | null = null,
    public message: string | null = null,
    public buttonText: string | null = null,
    public visible: boolean = false
  ) {}
}
