import axios from 'axios';
import { IEmployee } from '@/shared/model/employee.model';
import { IPersonalPin } from '@/shared/model/personal-pin.model';

const baseApiUrl = 'api/personal-pins';

export default class PersonalPinExtendedService {
  /**
   * Replaces src/api/services/employee.service.ts#loginEmployee
   */
  public login(userID: string, pin: string): Promise<boolean> {
    const login = {
      number: userID,
      password: pin,
    };
    return new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/pin-check`, login)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param entity
   */
  public forgotPersonalPin(entity: { [k: string]: any }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/forgot`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * @param params
   */
  public findBy(params: { [k: string]: any }): Promise<IPersonalPin> {
    return new Promise<IPersonalPin>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}`, { params })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Updates PersonalPin entity using the employee number as id
   * @param entity - a dynamic object that must include number email
   */
  public updatePersonalPin(entity: { [k: string]: any }): Promise<IEmployee> {
    return new Promise<IEmployee>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Creates PersonalPin
   * @param entity
   */
  public createPersonalPin(entity: { [k: string]: any }): Promise<IEmployee> {
    return new Promise<IEmployee>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
