import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';

import '@/shared/config/dayjs';

// BEGIN SPE Custom components
import AppBar from '@/core/spe/app-bar/app-bar.vue';
import LeftDrawer from '@/core/spe/left-drawer/left-drawer.vue';
import SpeBottomNavigation from '@/core/spe/spe-bottom-navigation/spe-bottom-navigation.vue';
import LoginService from '@/account/login.service';
import {
  GoRightComponent as GoRight,
  GoRightDrawerComponent as GoRightDrawer,
  GoAlertStackComponent as GoAlertStack,
} from '@gv/ammo-grome';
import { Inject } from 'vue-property-decorator';
// End SPE Custom components

// Begin SPE define inactivity time
const inactivityTimeout = 300000; // 5 minutes (in milliseconds)
// End SPE define inactivity time

@Component({
  components: {
    /* BEGIN SPE
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,

    'jhi-footer': JhiFooter,
    */
    'marut-app-bar': AppBar,
    'marut-left-drawer': LeftDrawer,
    'go-right': GoRight,
    'go-right-drawer': GoRightDrawer,
    'go-alert-stack': GoAlertStack,
    'spe-bottom-navigation': SpeBottomNavigation,
    /* END SPE */
  },
})
export default class App extends Vue {
  // BEGIN SPE
  @Inject('loginService')
  private loginService!: () => LoginService;

  private drawer = false;

  private drawerRight = false;

  private right = false;

  private left = false;

  private inactivityTimer: any = null;

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  /*
   * Set inactivity timer
   */
  mounted() {
    this.setupInactivityTimer();
    window.addEventListener('mousemove', this.resetInactivityTimer);
    window.addEventListener('keydown', this.resetInactivityTimer);
  }

  destroyed() {
    window.removeEventListener('mousemove', this.resetInactivityTimer);
    window.removeEventListener('keydown', this.resetInactivityTimer);
  }

  setupInactivityTimer() {
    this.inactivityTimer = setTimeout(() => {
      // Perform sign-out logic here
      this.loginService().logout();
      console.log('User inactive. Signing out...');

      // Check if the current route is not "/" before navigating
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    }, inactivityTimeout);
  }

  resetInactivityTimer() {
    clearTimeout(this.inactivityTimer);
    this.setupInactivityTimer();
  }
  // END SPE
}
