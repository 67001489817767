import { IImage } from '@/shared/model/image.model';

export interface IEmployee {
  // SPE Begin custom primary key names not detected <a href="https://github.com/jhipster/generator-jhipster/issues/23848"></a>
  number?: string;
  // SPE End custom primary key names not detected
  name?: string;
  address?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  residenceCountry?: string | null;
  country?: string | null;
  phone?: string | null;
  mobile?: string | null;
  birthDate?: Date;
  admissionDate?: Date | null;
  email?: string | null;
  citizenCardNumber?: string | null;
  citizenCardExpireDate?: Date | null;
  fiscalNumber?: string | null;
  socialSecurityNumber?: string | null;
  ticketType?: number | null;
  ticketNumber?: string | null;
  facility?: string | null;
  department?: string | null;
  sector?: string | null;
  section?: string | null;
  costCenter?: string | null;
  company?: string;
  site?: string;
  image?: IImage;
}

export class Employee implements IEmployee {
  constructor(
    public number?: string,
    public name?: string,
    public address?: string | null,
    public locality?: string | null,
    public postalCode?: string | null,
    public residenceCountry?: string | null,
    public country?: string | null,
    public phone?: string | null,
    public mobile?: string | null,
    public birthDate?: Date,
    public admissionDate?: Date | null,
    public email?: string | null,
    public citizenCardNumber?: string | null,
    public citizenCardExpireDate?: Date | null,
    public fiscalNumber?: string | null,
    public socialSecurityNumber?: string | null,
    public ticketType?: number | null,
    public ticketNumber?: string | null,
    public facility?: string | null,
    public department?: string | null,
    public sector?: string | null,
    public section?: string | null,
    public costCenter?: string | null,
    public company?: string,
    public site?: string,
    public image?: IImage
  ) {}
}
