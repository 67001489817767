import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router, { RouteConfig } from 'vue-router';

const Home = () => import('@/core/home/home.vue');
const Error = () => import('@/core/error/error.vue');
import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';
// BEGIN SPE custom components
import EmployeeAuthentication from '@/account/spe/employee-authentication/employee-authentication.vue';
import EmployeeAbsenceUpdate from '@/entities/spe/employee-absence/employee-absence-update.vue';
import { Authority } from '@/shared/security/authority';
import PinCreateOrUpdate from '@/account/spe/employee-authentication/pin-create-or-update.vue';
import PinForgot from '@/account/spe/employee-authentication/pin-forgot.vue';
import EmployeeDetail from '@/account/spe/employee-details/employee-details.vue';
// BEGIN End custom components

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [
    // BEGIN SPE custom router
    /*
        {
          path: '/',
          name: 'Home',
          component: Home
        },
        {
          path: '/forbidden',
          name: 'Forbidden',
          component: Error,
          meta: { error403: true }
        },
        {
          path: '/not-found',
          name: 'NotFound',
          component: Error,
          meta: { error404: true }
        },
        ...admin,
        entities,
        ...pages
      */
    {
      path: '/',
      name: 'EmployeeAuthentication',
      component: EmployeeAuthentication,
    },
    {
      path: '/employee-absence/new',
      name: 'EmployeeAbsenceCreate',
      component: EmployeeAbsenceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: EmployeeAuthentication,
    },
    {
      path: '/personal-pins/create',
      name: 'PinCreate',
      component: PinCreateOrUpdate,
    },
    {
      path: '/personal-pins/update',
      name: 'PinUpdate',
      component: PinCreateOrUpdate,
      meta: { authorities: [Authority.USER] },

    },
    {
      path: '/employee/details',
      name: 'EmployeeDetail',
      component: EmployeeDetail,
      meta: { authorities: [Authority.USER] },

    },
    {
      path: '/personal-pins/reset/:token',
      name: 'PinReset',
      component: PinCreateOrUpdate,
    },
    {
      path: '/personal-pins/forgot',
      name: 'PinForgot',
      component: PinForgot,
    },

    // BEGIN End custom router
  ]
});

export default router;
