export interface IEmployeeAbsence {
  id?: number;
  company?: string;
  site?: string;
  employeeNumber?: number;
  employeeFirstName?: string;
  employeeLastName?: string;
  expectedStartDate?: string;
  expectedEndDate?: string;
  expectedStartTime?: string;
  expectedEndTime?: string;
  absenceCode?: number;
  absenceDescription?: string;
  description?: string;
}

export class EmployeeAbsence implements IEmployeeAbsence {
  constructor(
    public id?: number,
    public company?: string,
    public site?: string,
    public employeeNumber?: number,
    public employeeFirstName?: string,
    public employeeLastName?: string,
    public expectedStartDate?: string,
    public expectedEndDate?: string,
    public expectedStartTime?: string,
    public expectedEndTime?: string,
    public absenceCode?: number,
    public absenceDescription?: string,
    public description?: string
  ) {}
}
