import { render, staticRenderFns } from "./security-data.vue?vue&type=template&id=a0a8840e&scoped=true&"
import script from "./security-data.component.ts?vue&type=script&lang=ts&"
export * from "./security-data.component.ts?vue&type=script&lang=ts&"
import style0 from "./security-data.vue?vue&type=style&index=0&id=a0a8840e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0a8840e",
  null
  
)

export default component.exports