import { Module } from 'vuex';
import { Employee } from '@/shared/model/employee.model';

// eslint-disable-next-line
export const employeeStore: Module<any, any> = {
  state: {
    employee: {} as Employee,
  },
  getters: {
    employee: state => state.employee,
    absence: state => state.absence,
    authenticated: state => JSON.stringify(state.employee) !== '{}',
  },
  mutations: {
    setEmployee(state, employee) {
      state.employee = employee;
    },

    setEmployeeImage(state, image) {
      state.employee.image = image;
    },

    setEmployeeAbsence(state, absence) {
      state.absence = absence;
    },
  },
  actions: {
    setEmployee({ commit }, employee) {
      commit('setEmployee', employee);
    },
    setEmployeeImage({ commit }, employee) {
      commit('setEmployeeImage', employee);
    },
    logout({ commit }) {
      commit('setEmployee', {});
      commit('setEmployeeAbsence', {});
    },
  },
};
