/*eslint-disable*/
import { Provide, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { IEmployee, Employee } from '@/shared/model/employee.model';
import EmployeeExtendedService from '@/account/spe/employee-authentication/employee-extended.service';
import { AlertMessageProperties, AlertMessagePropertiesType } from '@/shared/model/spe/alert-message-properties.model';
import type { IAlertMessageProperties } from '@/shared/model/spe/alert-message-properties.model';

import AlertMessage from '@/core/spe/alert-message/alert-message.vue';
import PersonalPinExtendedService from '@/account/spe/employee-authentication/personal-pin-extended.service';
import { AxiosError } from 'axios';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  components: {
    'alert-message': AlertMessage,
  },
})
export default class ForgotPin extends Vue {
  public email = '';
  public errors: { email: string } = null;
  public isSubmitting = false;

  @Provide('personalPinExtendedService')
  public personalPinExtendedService = () => new PersonalPinExtendedService();

  @Provide('employeeExtendedService')
  public employeeExtendedService = () => new EmployeeExtendedService();

  public alertMessageProperties: IAlertMessageProperties = new AlertMessageProperties();

  public alert: { [k: string]: any } = {
    type: 'success',
    message: null,
    enable: false,
  };

  public showSuccess(message: string): void {
    this.alertMessageProperties.type = AlertMessagePropertiesType.Success;
    this.alertMessageProperties.message = message;
    this.alertMessageProperties.buttonText = `${this.$t('spe.marutApp.employee.back')}`;
    this.alertMessageProperties.visible = true;
  }

  // eslint-disable-next-line
  public showError(message: string): void {
    this.alertMessageProperties.type = AlertMessagePropertiesType.Error;
    this.alertMessageProperties.message = message;
    this.alertMessageProperties.buttonText = `${this.$t('spe.marutApp.employee.back')}`;
    this.alertMessageProperties.visible = true;
  }

  public onAlertMessageClick(): void {
    this.alertMessageProperties.visible = false;

    if (
      this.alertMessageProperties.type === AlertMessagePropertiesType.Success ||
      this.alertMessageProperties.type === AlertMessagePropertiesType.Error
    ) {
      this.home();
    }
  }

  public home(): void {
    this.$router.push({ name: 'EmployeeAuthentication' });
  }

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.number) {
        vm.retrieveEmployee(to.params.number);
      }
    });
  }

  public get isEmailSet(): boolean {
    this.validateEmail();
    return this.errors === null;
  }

  public validateEmail(): void {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!this.email || this.email === '') {
      this.errors = { email: this.$t('spe.marutApp.employee.forgotPin.validationMessages.emailRequired').toString() };
    } else if (!emailRegex.test(this.email)) {
      this.errors = { email: this.$t('spe.marutApp.employee.forgotPin.validationMessages.invalidEmail').toString() };
    } else {
      this.errors = null;
    }
  }

  public previousState(): void {
    this.$router.go(-1);
  }

  public handleSubmit() {
    this.isSubmitting = true;
    this.alert.enable = false;

    this.employeeExtendedService()
      .filter({ filter: `email-is-${this.email}-and-admissiondate-is-null` })
      .then(res => {
        if (res.length === 0) {
          throw new Error(this.$t('spe.marutApp.employee.forgotPin.validationMessages.invalidEmail').toString());
        } else if (res.length > 1) {
          throw new Error(this.$t('spe.marutApp.employee.forgotPin.validationMessages.multipleAddressFound').toString());
        }
        return res[0];
      })
      .then(entity => {
        return this.personalPinExtendedService().forgotPersonalPin(entity);
      })
      .then(res => {
        this.isSubmitting = false;
        const message = this.$t('spe.marutApp.employee.forgotPin.validationMessages.emailNotification');
        this.showSuccess(message.toString());
      })
      .catch(err => {
        this.isSubmitting = false;
        this.showError(err instanceof AxiosError ? err.response.data.message : err);
      });
  }
}
