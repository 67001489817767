import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import { Employee, IEmployee } from '@/shared/model/employee.model';
import { IPersonalPin, PersonalPin } from '@/shared/model/personal-pin.model';
import EmployeeService from '@/entities/employee/employee.service';
import EmployeeImageService from './employee-image.service';
import VuePinCodeInput from '@/account/spe/vue-pin-code-input/vue-pin-code-input.vue';
import EmployeeExtendedService from '@/account/spe/employee-authentication/employee-extended.service';
import PersonalPinExtendedService from '@/account/spe/employee-authentication/personal-pin-extended.service';
import { AxiosError } from 'axios';

@Component({
  components: {
    'employee-pin': VuePinCodeInput,
  },
})
export default class EmployeeAuthentication extends Vue {
  @Provide('employeeService')
  private employeeService = () => new EmployeeService();
  @Provide('employeeExtendedService')
  private employeeExtendedService = () => new EmployeeExtendedService();
  @Provide('personalPinExtendedService')
  private personalPinExtendedService = () => new PersonalPinExtendedService();

  @Provide('employeeImageService')
  private employeeImageService = () => new EmployeeImageService();

  private search = '';

  private timeout = 0;

  private employee: IEmployee = new Employee();

  private personalPin: IPersonalPin = new PersonalPin();

  private password: string | null = null;

  private isLoading = false;

  private employees: Array<IEmployee> = [];

  private alert = false;

  private alertMessage: string | null = null;
  private currentLanguage = '';

  private get isEmployeeSet(): boolean {
    return this.employee && (this.employee.number ?? 0) !== 0;
  }
  private get isPasswordSet(): boolean {
    return (this.password ?? '').length !== 0;
  }

  @Watch('search')
  onSearchChange(val: string): void {
    if (!val) {
      return;
    }

    // Minimum search size
    if (val.length < 2) {
      return;
    }

    // Check if new value is the selected item
    //@ts-ignore
    if (this.employee && (this.employee.name === val || this.employee.number === parseInt(val, 10))) {
      return;
    }

    this.employees = [];
    this.isLoading = true;
    this.searchEmployeesDebounced();
  }

  @Watch('onSelectedEmployee')
  onSelectedEmployee(val: string): void {
    if (val) {
      const params: { [k: string]: any } = {
        filter: `number-is-${this.employee.number}`,
      };

      this.personalPinExtendedService()
        .findBy(params)
        .then(res => {
          this.personalPin = res;
        })
        .catch(error => {
          if (error instanceof AxiosError && error.response && error.response.status === 404) {
            this.personalPin = null;
          } else {
            this.alert = true;
            this.alertMessage = `${this.$t('spe.marutApp.employee.retrievePersonalPinError')}`;
          }
        });
    }
  }

  async login() {
    if ((this.password ?? '') === '') return;

    const userID = this.employee.number;

    let loggedIn = false;

    // catch network error
    try {
      // eslint-disable-next-line
      loggedIn = await this.personalPinExtendedService().login(`${userID}`, this.password!);
    } catch (error) {
      console.error(error);
      this.alertMessage = `${this.$t("login.messages.error['server.not.reachable']")}`;
      this.alert = true;
      this.isLoading = false;
      return;
    }

    // catch wrong login
    if (!loggedIn) {
      // alert
      this.alertMessage = `${this.$t('login.messages.error.authentication')}`;
      this.alert = true;
      return;
    }

    // add employee image to vuex
    try {
      const employee = await this.employeeService().find(this.employee.number);
      employee.image.image = `data:image/png;base64,${employee.image.image}`;
      employee.admissionDate = new Date(employee.admissionDate);
      employee.birthDate = new Date(employee.birthDate);
      employee.citizenCardExpireDate = new Date(employee.citizenCardExpireDate);

      // this.$store.dispatch('setEmployee', `data:image/png;base64,${employeeImage.image}`);
      this.$store.dispatch('setEmployee', employee);
    } catch (error) {
      this.alertMessage = `${this.$t("login.messages.error['server.not.reachable']")}`;
      // console.error(error);
    }

    // redirect employee to absence form in both cases: image found or not found
    this.$router.push({ name: 'EmployeeAbsenceCreate' });
  }

  searchEmployeesDebounced() {
    if (!this.search) {
      return;
    }
    // Minimum search size
    if (this.search.length < 2) {
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    // @ts-ignore
    this.timeout = setTimeout(async () => {
      try {
        if (this.search) {
          const employees = await this.employeeExtendedService().search(this.search);
          this.employees = employees;
        }
      } catch (error) {
        this.alertMessage = `${this.$t("login.messages.error['server.not.reachable']")}`;
        this.alert = true;
      }
      this.isLoading = false;
    }, 400);
  }

  // eslint-disable-next-line
  customFilter(item: IEmployee, queryText: string) {
    const name = (item.name || '').toLowerCase().includes(queryText.toLowerCase());
    const id = `${item.number}`.includes(queryText);
    return name || id;
  }

  /**
   * Stopping browsers, in special Chrome from ignoring autocomplete=off
   */
  // eslint-disable-next-line
  createNewPasswordUniqueId(): string {
    return `new-password-${new Date().getTime()}`;
  }
}
