export interface IPersonalPin {
  id?: number;
  number?: number;
  name?: string;
  password?: string;
  resetPasswordToken?: string | null;
  resetPasswordTokenAt?: Date | null;
  acceptGdpr?: boolean | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

export class PersonalPin implements IPersonalPin {
  constructor(
    public id?: number,
    public number?: number,
    public name?: string,
    public password?: string,
    public resetPasswordToken?: string | null,
    public resetPasswordTokenAt?: Date | null,
    public acceptGdpr?: boolean | null,
    public createdAt?: Date | null,
    public updatedAt?: Date | null
  ) {
    this.acceptGdpr = this.acceptGdpr ?? false;
  }
}
