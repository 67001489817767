import { Component, Emit, Inject, Prop, Vue } from 'vue-property-decorator';
import LoginService from '@/account/login-extended.service';
import { AlertMessageProperties, AlertMessagePropertiesType } from '@/shared/model/spe/alert-message-properties.model';
import type { IAlertMessageProperties } from '@/shared/model/spe/alert-message-properties.model';

// eslint-disable-next-line
const successImage = require('@/../content/images/spe/success.svg');
// eslint-disable-next-line
const errorImage = require('@/../content/images/spe/error.svg');



@Component({})
export default class AlertMessage extends Vue {
  @Inject('loginService')
  private loginService!: () => LoginService;

  @Prop({
    default: () => new AlertMessageProperties(),
  })
  private properties!: IAlertMessageProperties;

  /**
   * Gets image source property
   */
  public get src(): string {
    if (this.properties.type === AlertMessagePropertiesType.Error) {
      return errorImage;
    }
    return successImage;
  }

  /**
   * Gets image alt property
   */
  public get alt(): string {
    if (this.properties.type === 'error') {
      return 'error';
    }
    return 'success';
  }

  /**
   * Rename refactoring goBack
   */
  @Emit('click')
  // eslint-disable-next-line
  click() {
    // this.$router.go(-1)
  }

  public hasTitle(): boolean {
    return this.properties.title !== null;
  }
}
