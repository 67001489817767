import axios from 'axios';
// SPE Begin Custom imports
import { ERP_HEADERS } from '@gv/ammo-astra';
// SPE End imports

const TIMEOUT = 1000000;
const onRequestSuccess = config => {
  // SPE Begin add tenant header
  const tenant = process.env.ERP_TENANT_ID;
  if (tenant) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers[ERP_HEADERS.TENANT_ID] = tenant;
  }
  // SPE End add tenant header
  // SPE Begin add language query parameter
  const language = localStorage.getItem('currentLanguage');
  if (language) {
    if (!config.params) {
      config.params = {};
    }
    config.params['language'] = language;
  }
  // SPE End add language query parameter
  config.timeout = TIMEOUT;
  config.url = `${SERVER_API_URL}${config.url}`;
  return config;
};
const setupAxiosInterceptors = (onUnauthenticated, onServerError) => {
  const onResponseError = err => {
    const status = err.status || err.response.status;
    if (status === 403 || status === 401) {
      return onUnauthenticated(err);
    }
    if (status >= 500) {
      return onServerError(err);
    }
    return Promise.reject(err);
  };

  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(res => res, onResponseError);
  }
};

export { onRequestSuccess, setupAxiosInterceptors };
