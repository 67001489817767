import { Store } from 'vuex'

export default class LoginService {
  private store: Store<unknown>

  constructor(store: Store<unknown>) {
    this.store = store
  }
  // public login(): void {}

  public logout(): void {
    this.store.dispatch('logout')
  }
}
