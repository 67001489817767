import { Prop, Provide, Vue } from 'vue-property-decorator';
import type { IEmployee } from '@/shared/model/employee.model';
import Component from 'vue-class-component';
import AlertMessage from '@/core/spe/alert-message/alert-message.vue';
import { IPersonalPin, PersonalPin } from '@/shared/model/personal-pin.model';
import PersonalPinExtendedService from '@/account/spe/employee-authentication/personal-pin-extended.service';
import { IAlertMessageProperties } from '@/shared/model/spe/alert-message-properties.model';

import { AlertMessageProperties, AlertMessagePropertiesType } from '@/shared/model/spe/alert-message-properties.model';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

const validations: any = {
  oldPassword: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(4),
  },
  password: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(4),
  },
  confirmPassword: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(4),
  },
};
@Component({
  methods: { minLength, maxLength, required },
  components: {
    'alert-message': AlertMessage,
  },
  validations,
})
export default class SecurityData extends Vue {
  @Prop() private readonly value!: IEmployee;
  @Prop() private readonly currentLanguage!: string;

  public isSubmitting = false;

  public oldPassword: string | null = null;

  public password: string | null = null;

  public confirmPassword: string | null = null;

  public resetSucceeded = false;

  public resetError: string | null = null;

  public alert: { [k: string]: any } = {
    type: 'success',
    message: null,
    enable: false,
  };

  @Provide('personalPinExtendedService')
  private personalPinExtendedService = () => new PersonalPinExtendedService();
  public alertMessageProperties: IAlertMessageProperties = new AlertMessageProperties();
  public personalPin: IPersonalPin = new PersonalPin();

  public get hasError(): boolean {
    return this.resetError !== null;
  }

  public showHttpError(httpErrorResponse: any): void {
    this.alertMessageProperties.type = AlertMessagePropertiesType.Error;
    this.alertMessageProperties.message = httpErrorResponse.data.message;
    this.alertMessageProperties.buttonText = `${this.$t('spe.marutApp.employee.back')}`;
    this.alertMessageProperties.visible = true;
  }

  public handleSubmit() {
    if (!this.validate()) {
      this.clear();
      setTimeout(() => {
        this.resetError = null;
      }, 5000);
      return;
    }

    const params = {
      number: this.value.number,
      password: this.password,
    };

    this.alert.enable = false;
    this.isSubmitting = true;
    this.$emit('isLoadingChange', this.isSubmitting);

    this.personalPinExtendedService()
      .login(this.value.number, this.oldPassword)
      .then(oldPasswordIsCorrect => {
        if (!oldPasswordIsCorrect) {
          this.resetError = this.$t('spe.marutApp.employee.details.securityData.updatePin.errors.currentPassword').toString();
          this.clear();
          setTimeout(() => {
            this.resetError = null;
          }, 5000);
          return false;
        }
        return this.personalPinExtendedService()
          .updatePersonalPin(params)
          .then(res => {
            return true;
          });
      })
      .then(value => {
        this.isSubmitting = false;
        this.$emit('isLoadingChange', this.isSubmitting);
        if (value) {
          this.resetSucceeded = true;
        }
      })
      .catch(error => {
        this.isSubmitting = false;
        this.$emit('isLoadingChange', this.isSubmitting);
        this.showHttpError(error.response);
      });
  }

  public clear() {
    this.$v.$reset();
    this.oldPassword = null;
    this.password = null;
    this.confirmPassword = null;
  }
  public validate(): boolean {
    if (this.password !== this.confirmPassword) {
      this.resetError = this.$t('spe.marutApp.employee.details.securityData.updatePin.errors.notMatch').toString();
      return false;
    }
    return true;
  }

  public clearOldPassword() {
    this.oldPassword = '';
    this.$v.oldPassword.$reset();
  }

  public clearPassword() {
    this.password = '';
    this.$v.password.$reset();
  }
  public clearConfirmPassword() {
    this.confirmPassword = '';
    this.$v.confirmPassword.$reset();
  }

  public goBack() {
    this.$router.go(-1);
  }

  public vuelidateErrors(model: any, options?: { [k: string]: any }): string[] {
    if (!model.$dirty) return [];

    const errors = [];

    if (model.hasOwnProperty('required') && !model.required) {
      errors.push(this.$t('entity.validation.required'));
    }

    if (model.hasOwnProperty('minLength') && !model.minLength) {
      errors.push(this.$t('entity.validation.minlength', { min: options.min }));
    }

    if (model.hasOwnProperty('maxLength') && !model.maxLength) {
      errors.push(this.$t('entity.validation.maxlength', { max: options.max }));
    }

    return errors;
  }
}
