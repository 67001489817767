/**
 * @see <a href="https://github.com/Seokky/vue-pincode-input">vue-pincode-input</a>
 */

import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { CellInputType, Cell } from './types';
import { BASE_REF_NAME, CELL_REGEXP, DEFAULT_INPUT_TYPE, SECURE_INPUT_TYPE } from './constants';

@Component({})
export default class VuePinCodeInput extends Vue {
  @Prop(String)
  readonly value!: string;

  @Prop({ type: Number, default: 4 })
  readonly length!: number;

  @Prop({ type: Boolean, default: true })
  readonly autofocus!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly secure!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly characterPreview!: boolean;

  @Prop({ type: Number, default: 300 })
  readonly charPreviewDuration!: number;

  private baseRefName = BASE_REF_NAME;

  private focusedCellIdx = 0;

  private cells: Cell[] = [];

  // eslint-disable-next-line
  private watchers: { [k: string]: any } = {};

  private cellsInputTypes = {};

  public get pinCodeComputed(): string {
    return this.cells.reduce((pin, cell) => pin + cell.value, '');
  }

  @Watch('value')
  onValueChanged(val: string): void {
    if (val) {
      this.onParentValueUpdated();
    } else {
      this.reset();
    }
  }

  @Watch('length')
  onLengthChanged(): void {
    this.reset();
  }

  onPinCodeComputedChanged(val: string): void {
    this.$emit('input', val);
  }

  mounted(): void {
    this.init();
    this.onParentValueUpdated();

    if (this.autofocus) {
      this.$nextTick(this.focusCellByIndex);
    }
  }

  /* init stuff */
  init(): void {
    const inputType = this.getRelevantInputType();
    for (let key = 0; key < this.length; key += 1) {
      this.setCellObject(key);
      this.setCellInputType(key, inputType);
      this.setCellWatcher(key);
    }
  }

  setCellObject(key: number): void {
    this.$set(this.cells, key, { key, value: '' });
  }

  setCellInputType(index: number, inputType: CellInputType = SECURE_INPUT_TYPE): void {
    this.$set(this.cellsInputTypes, index, inputType);
  }

  setCellWatcher(index: number): void {
    const watchingProperty = `cells.${index}.value`;

    this.watchers[watchingProperty] = this.$watch(watchingProperty, (newVal, oldVal) => this.onCellChanged(index, newVal, oldVal));
  }

  /* handlers */
  onParentValueUpdated(): void {
    if (this.value.length !== this.length) {
      this.$emit('input', this.pinCodeComputed);
      return;
    }

    this.value.split('').forEach((cell: string, idx: number) => {
      this.cells[idx].value = cell || '';
    });
  }

  /* eslint-disable */
  onCellChanged(index: number, newVal: string, oldVal: string): void {
    if (!this.isTheCellValid(newVal, false)) {
      this.cells[index].value = '';
      return;
    }

    this.focusNextCell();

    /* performing character preview if it's enabled */
    if (this.secure && this.characterPreview) {
      setTimeout(this.setCellInputType, this.charPreviewDuration, index);
    }
  }

  onCellErase(index: number, e: Event): void {
    const isThisCellFilled = this.cells[index].value.length;

    if (!isThisCellFilled) {
      this.focusPreviousCell();
      e.preventDefault();
    }
  }

  onKeyDown(e: KeyboardEvent): void {
    switch (e.keyCode) {
      /* left arrow key */
      case 37:
        this.focusPreviousCell();
        break;

      /* right arrow key */
      case 39:
        this.focusNextCell();
        break;

      default:
        break;
    }
  }

  /* reset stuff */

  reset(): void {
    this.unwatchCells();
    this.init();
    this.focusCellByIndex();
  }

  unwatchCells(): void {
    const watchers = Object.keys(this.watchers);
    watchers.forEach(name => this.watchers[name]());
  }

  /* helpers */

  // eslint-disable-next-line
  isTheCellValid(cell: string, allowEmpty = true): boolean {
    if (!cell) {
      return allowEmpty ? cell === '' : false;
    }

    return !!cell.match(CELL_REGEXP);
  }

  getRelevantInputType(): CellInputType {
    return this.secure && !this.characterPreview ? SECURE_INPUT_TYPE : DEFAULT_INPUT_TYPE;
  }

  focusPreviousCell(): void {
    if (!this.focusedCellIdx) return;

    this.focusCellByIndex(this.focusedCellIdx - 1);
  }

  focusNextCell(): void {
    if (this.focusedCellIdx === this.length - 1) return;

    this.focusCellByIndex(this.focusedCellIdx + 1);
  }

  focusCellByIndex(index = 0): void {
    const ref = `${this.baseRefName}${index}`;
    const el = (this.$refs[ref] as HTMLInputElement[])[0];

    el.focus();
    el.select();

    this.focusedCellIdx = index;
  }
}
