export interface IEmployeeAbsenceType {
  // SPE Begin custom primary key name remove once is fixed <a href="https://github.com/jhipster/generator-jhipster/issues/23848"></a>
  code?: string;
  // SPE End custom primary key name remove once is fixed <a href="https://github.com/jhipster/generator-jhipster/issues/23848"></a>
  description?: string;
  daysInAdvance?: number | null;
}

export class EmployeeAbsenceType implements IEmployeeAbsenceType {
  // SPE Begin custom primary key name remove once is fixed <a href="https://github.com/jhipster/generator-jhipster/issues/23848"></a>
  constructor(public code?: string, public description?: string, public daysInAdvance?: number | null) {}
  // SPE End custom primary key name remove once is fixed <a href="https://github.com/jhipster/generator-jhipster/issues/23848"></a>
}
