import { Store } from 'vuex'
import VueRouter from 'vue-router'

/* eslint-disable */
export default class AccountExtendedService {
  constructor(private store: Store<any>, private router: VueRouter) {
    this.init()
  }

  public init(): void {
  }

  public get authenticated(): boolean {
    return this.store.getters.authenticated
  }
}
