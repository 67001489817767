import axios from 'axios';

import { IEmployee } from '@/shared/model/employee.model';
import { IImage } from '@/shared/model/image.model';

const baseApiUrl = 'api/employees';

export default class EmployeeService {
  public find(id: string): Promise<IEmployee> {
    return new Promise<IEmployee>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // SPE Begin Property 'update' does not exist on type 'SinonStubbedInstance<EmployeeService>' when running tests
  public create(entity: IEmployee): Promise<IEmployee> {
    return new Promise<IEmployee>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IEmployee): Promise<IEmployee> {
    return new Promise<IEmployee>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.number}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  // SPE Begin Property 'update' does not exist on type 'SinonStubbedInstance<EmployeeService>' when running tests
}
