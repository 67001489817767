import axios from 'axios';
import { IEmployee } from '@/shared/model/employee.model';

const baseApiUrl = 'api/employees';

export default class EmployeeExtendedService {
  /**
   *
   * @param id
   */
  public find(id: string): Promise<IEmployee> {
    return new Promise<IEmployee>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/active/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public filter(params: { [k: string]: any }): Promise<Array<IEmployee>> {
    return new Promise<Array<IEmployee>>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}`, { params })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Replaces src/api/services/employee.service.ts#searchEmployee
   */
  public async search(identifierPattern: string): Promise<Array<IEmployee>> {
    if (!identifierPattern) {
      return new Promise<Array<IEmployee>>(resolve => {
        resolve(new Array<IEmployee>());
      });
    }

    // Minimum search size
    if (identifierPattern.length < 2) {
      return new Promise<Array<IEmployee>>(resolve => {
        resolve(new Array<IEmployee>());
      });
    }

    const data = (await axios.get(`${baseApiUrl}/active/search`, { params: { pattern: identifierPattern } })).data;

    if (typeof data === 'string') {
      // MediaType.APPLICATION_NDJSON_VALUE
      return data
        .split('\n')
        .filter((employeeString: string) => employeeString !== '')
        .map((employee: string) => JSON.parse(employee)) as Array<IEmployee>;
    } else {
      // MediaType.APPLICATION_JSON
      return data.map((it: any) => {
        it.number = parseInt(it.number, 10);
        return it;
      });
    }
  }
}
