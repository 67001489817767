/* eslint-disable */
import { Component, Provide, Vue } from 'vue-property-decorator';
import { Employee, IEmployee } from '@/shared/model/employee.model';
import { IPersonalPin, PersonalPin } from '@/shared/model/personal-pin.model';
import VuePinCodeInput from '@/account/spe/vue-pin-code-input/vue-pin-code-input.vue';
import EmployeeExtendedService from '@/account/spe/employee-authentication/employee-extended.service';
import PersonalPinExtendedService from '@/account/spe/employee-authentication/personal-pin-extended.service';
import type { IAlertMessageProperties } from '@/shared/model/spe/alert-message-properties.model';
import { AlertMessageProperties, AlertMessagePropertiesType } from '@/shared/model/spe/alert-message-properties.model';
import AlertMessage from '@/core/spe/alert-message/alert-message.vue';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

Component.registerHooks(['beforeRouteEnter']);

const validations: any = {
  password: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(4),
  },
  confirmPassword: {
    minLength: minLength(4),
    maxLength: maxLength(4),
  },
};

@Component({
  methods: { required },
  components: {
    'employee-pin': VuePinCodeInput,
    'alert-message': AlertMessage,
  },
  validations,
})
export default class PinCreateOrUpdate extends Vue {
  public employee: IEmployee = new Employee();
  public personalPin: IPersonalPin = new PersonalPin();
  public isSubmitting = false;

  public password: string | null = null;
  public confirmPassword: string | null = null;

  @Provide('employeeExtendedService')
  public employeeExtendedService = () => new EmployeeExtendedService();

  @Provide('personalPinExtendedService')
  public personalPinExtendedService = () => new PersonalPinExtendedService();

  public alertMessageProperties: IAlertMessageProperties = new AlertMessageProperties();

  public alert: { [k: string]: any } = {
    type: 'success',
    message: null,
    enable: false,
  };

  public get passwordMatch(): boolean {
    if (this.password && this.confirmPassword) {
      return this.password === this.confirmPassword;
    } else {
      return false;
    }
  }

  private showHttpSuccess(message: string): void {
    this.alertMessageProperties.type = AlertMessagePropertiesType.Success;
    this.alertMessageProperties.message = message;
    this.alertMessageProperties.buttonText = `${this.$t('global.menu.home')}`;
    this.alertMessageProperties.visible = true;
  }

  // eslint-disable-next-line
  private showHttpError(httpErrorResponse: any): void {
    this.alertMessageProperties.type = AlertMessagePropertiesType.Error;
    this.alertMessageProperties.message = httpErrorResponse.data.message;
    this.alertMessageProperties.buttonText = `${this.$t('entity.action.back')}`;
    this.alertMessageProperties.visible = true;
  }

  private onAlertMessageClick(): void {
    this.alertMessageProperties.visible = false;

    if (
      this.alertMessageProperties.type === AlertMessagePropertiesType.Success ||
      this.alertMessageProperties.type === AlertMessagePropertiesType.Error ||
      this.isExpired(this.personalPin)
    ) {
      this.home();
    }
  }

  public home(): void {
    this.$router.push({ name: 'EmployeeAuthentication' });
  }

  /**
   * Checks if the provided date is within a time frame of 15 minutes from the current time.
   */
  public isExpired(personalPin: IPersonalPin): boolean {
    return new Date().getTime() - personalPin.resetPasswordTokenAt.getTime() > 15 * 60 * 1000;
  }

  public hasPassword() {
    return !!this.password;
  }

  private get isPasswordSet(): boolean {
    return !!this.password && !!this.confirmPassword;
  }

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.number) {
        vm.retrieveEmployee(to.params.number);
      }
      if (to.params.token) {
        vm.retrieveEmployeeToken(to.params.token);
      }
    });
  }

  public retrieveEmployee(employeeId) {
    this.employeeExtendedService()
      .find(employeeId)
      .then(res => {
        // @ts-ignore
        this.employee = res;
        this.employee.number = res.number;
      })
      .catch(error => {
        if (error.response) {
          this.showHttpError(error.response);
          // @ts-ignore
          this.alertMessageProperties.message = this.$t('spe.marutApp.employee.retrieveEmployeeError');
        }
      });
  }

  public retrieveEmployeeToken(resetPasswordToken: string) {
    const params: { [k: string]: any } = {
      filter: `resetpasswordtoken-is-${resetPasswordToken}`,
    };
    this.personalPinExtendedService()
      .findBy(params)
      .then(res => {
        //@ts-ignore
        this.employee = res;
        this.personalPin = res;
        this.personalPin.password = res.password;
        this.personalPin.resetPasswordTokenAt = new Date(res.resetPasswordTokenAt);
        if (this.isExpired(this.personalPin)) {
          this.alertMessageProperties.message = this.$t('spe.marutApp.employee.forgotPin.validationMessages.errorMessage').toString();
          this.alertMessageProperties.buttonText = `${this.$t('entity.action.back')}`;
          this.alertMessageProperties.visible = true;
        }
      })
      .catch(error => {
        if (error.response) {
          this.showHttpError(error.response);
          // @ts-ignore
          this.alertMessageProperties.message = this.$t('spe.marutApp.employee.forgotPin.validationMessages.errorMessage');
        }
      });
  }

  public handleSubmit() {
    this.alert.enable = false;
    this.isSubmitting = true;

    if (this.$route.name === 'PinCreate') {
      const params = {
        number: this.employee.number,
        name: this.employee.name,
        password: this.password,
        acceptGdpr: this.personalPin.acceptGdpr,
      };
      this.personalPinExtendedService()
        .createPersonalPin(params)
        .then(res => {
          this.employee = res;
          this.isSubmitting = false;
          const message = this.$t('spe.marutApp.employee.createPin.validationMessages.notificationSuccess');
          this.showHttpSuccess(`${message}`);
        })
        .catch(error => {
          this.showHttpError(error.response);
        });
    }
    if (this.$route.name === 'PinReset') {
      const params = {
        number: this.employee.number,
        password: this.password,
      };
      this.personalPinExtendedService()
        .updatePersonalPin(params)
        .then(res => {
          this.employee = res;
          this.isSubmitting = false;
          const message = this.$t('spe.marutApp.employee.details.securityData.updatePin.validationMessages.notificationSuccess');
          this.showHttpSuccess(`${message}`);
        })
        .catch(error => {
          this.showHttpError(error.response);
        });
    }
  }

  public vuelidateErrors(model: any, options?: { [k: string]: any }): string[] {
    if (!model.$dirty) return [];

    const errors = [];

    if (model.hasOwnProperty('required') && !model.required) {
      errors.push(this.$t('entity.validation.required'));
    }

    if (model.hasOwnProperty('minLength') && !model.minLength) {
      errors.push(this.$t('entity.validation.minlength', { min: options.min }));
    }

    if (model.hasOwnProperty('maxLength') && !model.maxLength) {
      errors.push(this.$t('entity.validation.maxlength', { max: options.max }));
    }

    return errors;
  }
}
