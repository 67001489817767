import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class EmployeeAccount extends Vue {
  public logout(): void {
    this.$emit('logout', {});
  }

  public openLogin(): void {
    this.$emit('openLogin', {});
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get initials(): string | null {
    if (this.$store.getters.employee.name) {
      const tokens: string[] = this.$store.getters.employee.name.split(' ');

      if (tokens.length === 1) {
        return tokens[0].charAt(0).toUpperCase();
      }
      return tokens[0].charAt(0).toUpperCase() + tokens[tokens.length - 1].charAt(0).toUpperCase();
    }
    return null;
  }
}
