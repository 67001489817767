import { render, staticRenderFns } from "./employee-absence-update.vue?vue&type=template&id=aba72798&"
import script from "./employee-absence-update.component.ts?vue&type=script&lang=ts&"
export * from "./employee-absence-update.component.ts?vue&type=script&lang=ts&"
import style0 from "./employee-absence-update.vue?vue&type=style&index=0&id=aba72798&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports