import { Prop, Vue } from 'vue-property-decorator';
import type { IEmployee } from '@/shared/model/employee.model';
import Component from 'vue-class-component';

@Component({})
export default class PersonalData extends Vue {
  @Prop() private readonly value!: IEmployee;
  @Prop() private readonly currentLanguage!: string;

  public isCitizenCardExpired = false;

  public get employee() {
    return this.value;
  }

  public get admissionDate(): string {
    return this.value.admissionDate.toLocaleDateString(this.currentLanguage);
  }

  public get formattedBirthDate(): string {
    return this.value.birthDate.toLocaleDateString(this.currentLanguage);
  }

  public get formattedCitizenCardExpireDate(): string {
    return this.value.citizenCardExpireDate.toLocaleDateString(this.currentLanguage);
  }

  public get citizenCardExpirationDateHint(): string {
    const now = new Date();
    const citizenCardDate = this.value.citizenCardExpireDate;

    if (citizenCardDate.getTime() < now.getTime()) {
      this.isCitizenCardExpired = true;
      return `${this.$t('spe.marutApp.employee.details.personalData.citizenCardExpired')}`;
    }
  }

  public get admissionDateHint(): string {
    const today = new Date();
    const admissionDate = this.value.admissionDate;

    let years = today.getFullYear() - admissionDate.getFullYear();
    let months = today.getMonth() - admissionDate.getMonth();

    if (months < 0) {
      years--;
      months = 12 + months;
    }

    // Format the years and months singular OR plural form
    const yearsAt =
      years === 1
        ? this.$t('spe.marutApp.employee.details.personalData.year')
        : this.$t('spe.marutApp.employee.details.personalData.years');
    const monthAt =
      months === 1
        ? this.$t('spe.marutApp.employee.details.personalData.month')
        : this.$t('spe.marutApp.employee.details.personalData.months');

    if (years > 0 && months > 0) {
      return `${years} ${yearsAt} ${this.$t('spe.marutApp.employee.details.personalData.and')} ${months} ${monthAt}`;
    } else if (years > 0) {
      return `${years} ${yearsAt}`;
    } else {
      return `${months} ${monthAt}`;
    }
  }
}
